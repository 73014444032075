import { gql } from "urql";

export const GET_LOAN_BY_OWNER: any = gql(`
    query GetLoanByOwner{
        GetLoanByOwner {
            _id
            name
            type
            purpose
            ltv
            owner {
                _id
                name
                lastname
            }
            onboarding
            investmentExperience
            loanData
        }  
    }
`)

export const GET_LOAN_BY_ID: any = gql(`
    query GetLoanById($id: String!){
        GetLoanById(id: $id) {
            _id
            name
        }  
    }
`)

export const GET_LOANS_BY_OWNER: any = gql(`
    query GetLoansByOwner($search: String){
        GetLoansByOwner(search: $search) {
            _id
            name
            type
            purpose
            ltv
            progress
            owner {
                _id
                name
                lastname
            }
            onboarding
            investmentExperience
            tasks {
                _id
                name
                key
                state
                phase
            }
        }  
    }
`)

export const GET_LOAN_DOCUMENTS: any = gql(`
    query GetLoanDocByLoan($loanId: String!){
        GetLoanDocByLoan(loanId: $loanId) {
            _id
            key
            name
            description
            expiration
            state
            hidden
            file {
                key
                name
                url
            }
        }  
    }
`)