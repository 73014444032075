import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';

const CardMarketRent = ({ vacantUnits }: any) => {
  const initialRent = 2000;
  const [rents, setRents] = useState(Array(vacantUnits).fill(initialRent));
  const [touched, setTouched] = useState(Array(vacantUnits).fill(false));

  const handleRentChange = (index: number, value: number) => {
    const newRents = [...rents];
    newRents[index] = value;
    setRents(newRents);

    const newTouched = [...touched];
    newTouched[index] = true;
    setTouched(newTouched);
  };

  const allTouched = touched.every(t => t);

  return (
    <blockquote className="CardMarketRent">
      <article className="flex-btw">
        <div>
          <h3 className="h4">Market rent</h3>
          <p className="body-card">
            Enter market rents for each of the vacant units
          </p>
        </div>
        <p className="h4 h4-w4">
          Vacant units: <span className="h4">{vacantUnits}</span>
        </p>
      </article>
      <article className="flex-btw">
        {Array.from({ length: vacantUnits }).map((_, index) => (
          <div key={index}>
            <CurrencyInput value={rents[index]} onChange={(value) => handleRentChange(index, value)} />
            <span className='month'>/month</span>
          </div>
        ))}
      </article>
      <Button id={"rent-button"} className={`button button-grey ${allTouched ? 'button-submit' : ''}`} disabled={!allTouched}>
        Add market rent for {vacantUnits} units to submit
      </Button>
    </blockquote>
  );
};

interface CurrencyInputProps {
  value: number;
  onChange: (value: number) => void;
  className?: string;
  moveCursorToEnd?: boolean;
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange, className, moveCursorToEnd = true }) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(value);
  };

  const setCursorToEnd = (element: HTMLSpanElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleInput = (event: React.FormEvent<HTMLSpanElement>) => {
    const newValue = parseInt(event.currentTarget.innerText.replace(/[^0-9]/g, ''), 10) || 0;
    onChange(newValue);
    event.stopPropagation();
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerText = formatCurrency(value);
      if (moveCursorToEnd && document.activeElement === spanRef.current) {
        setCursorToEnd(spanRef.current);
      }
    }
  }, [value]);

  const classNameFactory = (className: string) => {
    return `${className} body-regular body-regular-italic`;
  }

  return (
    <span
      contentEditable
      suppressContentEditableWarning
      onInput={handleInput}
      className={classNameFactory(className || '')}
      ref={spanRef}
    >
      {formatCurrency(value)}
    </span>
  );
};

export default CardMarketRent;
