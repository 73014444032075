import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { ArrowRightOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CardOwnership from '../CardOwnership/CardOwnership';
import CardOwnershipEdit from './CardOwnershipEdit/CardOwnershipEdit';
import { useCreateResponseTask } from 'services/task/mutations';
import { TaskContext } from '../EntityGuarantorCreation';
import DropdownBlue from '../../../../../../components/Atoms/Dropdown/DropdownBlue/DropdownBlue';
import ButtonEditOwnership from './ButtonEditOwnership';
import ButtonSubmit from './ButtonSubmit';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import { useGetEntitiesByOwner } from '../../../../../../services/entity/querys';
import { useGetOwnershipsByCreator } from '../../../../../../services/ownership/querys';
import { capitalizeNames } from '@common/utils/formatters/formatter';
import useGuarantorUtil from '../useGuarantorUtil';

interface Step2Props {
  setStep: Function;
  responseStep1: any;
  responsesStep2?: any[];
  step2Cache?: any[];
  step2Confirm?: any;
  taskEntityDocs?: Task;
  setHandlerSubmit: any;
  closeTask: any;
}
const Step2 = ({
  setStep,
  responseStep1,
  responsesStep2 = [],
  step2Cache,
  step2Confirm,
  taskEntityDocs,
  setHandlerSubmit,
  closeTask
}: Step2Props) => {
  const task: Task | any = useContext(TaskContext);

  const { data: responseCreateResponse, fetching, execute: createResponseTask } = useCreateResponseTask();

  const [ownerSelect, setOwnerSelect] = useState<any>();

  const { data: listOwnerships, reexecute: getOwnershipsByCreator } = useGetOwnershipsByCreator({});
  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    { variables: { ownerId: ownerSelect }, pause: true }
  );
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [entity, setEntity] = useState<{ name: string; key: string }>();
  const [ownerships, setOwnerships] = useState<any>([]);
  const [newOwner, setNewOwner] = useState<any>();
  const [ownerExpand, setOwnerExpand] = useState<any>();
  const [newPercentageLiveEdit, setNewPercentageLiveEdit] = useState<any>();
  const [ownerPercentageLiveEdit, setOwnerPercentageLiveEdit] = useState<any>();


  const { getSomeoneNews, selectOwnersCache } = useGuarantorUtil({
    ownerships,
    ownershipsUserCreator: listOwnerships
  });

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner({ requestPolicy: 'network-only' });
  }, [ownerSelect]);

  useEffect(() => {
    if (responsesStep2?.length > 0)
      assignOwnerships();
    else {
      const owner = listOwnerships?.find((item) => item._id === responseStep1?.metadata.ownerId);

      if (owner?._id) {
        const { __typename, stakes, files, ...metadata } = owner;
        createOwnership(
          {
            ...metadata,
            referenceId: owner._id,
            files: files?.map((item) => ({ ...item, state: TaskState.Draft })),
            ownership: responseStep1.key === '100%-entity' ? 100 : 100
          },
          TaskState.Incomplete
        );
      } else {
        setNewOwner(
          responseStep1?.metadata.ownerId !== 'someone_new'
            ? {
              name: owner?.name,
              lastname: owner?.lastname,
              ownership: responseStep1?.key === '100%-entity' ? 100 : null
            }
            : { ownership: responseStep1?.key === '100%-entity' ? 100 : null }
        );
      }
    }
    if (responseStep1?.metadata?.entity)
      setEntity({ name: responseStep1.metadata.entity, key: responseStep1.key });

    if (responseStep1?.metadata?.ownerId)
      setOwnerSelect(responseStep1?.metadata.ownerId);
  }, [responseStep1]);

  useEffect(() => {
    if (responseCreateResponse) {
      setNewOwner(null);
      setNewPercentageLiveEdit(null);
      getTaskResponses({ variables: { taskId: task._id }, requestPolicy: 'network-only' });
    }
  }, [responseCreateResponse]);

  useEffect(() => {
    assignOwnerships();
  }, [responsesStep2]);

  const assignOwnerships = (ownerships?: any) => {
    const _ownerships = [];

    for (const taskResponse of ownerships || responsesStep2) {
      _ownerships.push({
        key: taskResponse._id,
        state: taskResponse.state,
        files: taskResponse.files,
        ...taskResponse.metadata,
        taskResponse
      });
    }
    setOwnerships(_ownerships);
  };

  const createOwnership = (values: any, state?: TaskState) => {
    const { files, ...ownership } = values;

    ownership.name = capitalizeNames(ownership.name);
    ownership.lastname = capitalizeNames(ownership.lastname);

    const ownerId =
      responseStep1.metadata.ownerId &&
        responseStep1.metadata.ownerId !== 'someone_new'
        ? responseStep1.metadata.ownerId
        : undefined;

    let payload: any = {
      task: task._id,
      key: `ownership-${values.name}`,
      label: `Ownership ${values.name}`,
      step: 2,
      metadata:
        responsesStep2.length === 0
          ? { _id: ownerId, ...ownership }
          : ownership,
      state: state ? state : ownership.ownership >= 20 ? TaskState.Incomplete : TaskState.Draft
    };

    //Es un archivo recien subido
    const isFile = files?.some((item: any) => item.size);

    if (isFile) payload.files = files;
    else payload.filesExist = files;

    createResponseTask(payload);
  };
  
  const getTotal = () => {
    return ownerships?.filter((item: any) =>
      !ownerPercentageLiveEdit ||
      !ownerPercentageLiveEdit.ownerId ||
      (item._id !== ownerPercentageLiveEdit?.ownerId &&
        item.ownerId !== ownerPercentageLiveEdit?.ownerId)
    ).reduce(
      (total: number, ownership: any) => total + (ownership.ownership || 0),
      0
    ) + (newPercentageLiveEdit?.value || 0) + (ownerPercentageLiveEdit?.value || 0);
  }

  const changeEntity = (entityKey: string) => {
    setStep(1);
    setHandlerSubmit({ step: 1, active: true, metadata: { entityKey } });
  };

  const previousStep = () => setStep(1);

  const itemCardOwner = (ownership: any, index: number) => {
    const isExpand = (ownership?._id && ownerExpand?._id === ownership?._id) ||
      (ownership?.ownerId && ownerExpand?.ownerId === ownership?.ownerId) ?
      ownerExpand : undefined;

    return (
      <CardOwnership
        key={index}
        index={index}
        ownership={ownership}
        hiddenColorsState
        disabledForm={true}
        enabledDataBasic={false}
        hiddenTextEdit={true}
        hiddenSubmitChanges={true}
        isActionRemoved={!step2Confirm}
        otherButton={{ b: "Use this owner.", span: "You can edit their profile in the next step" }}
        onDeleteOwner={() => getOwnershipsByCreator({ requestPolicy: 'network-only' })}
        isExpand={isExpand}
        onExpand={setOwnerExpand}
        setNewPercentageLiveEdit={setOwnerPercentageLiveEdit}
      />
    );
  }

  return (
    <section className="step-2">
      <div className="section-1">
        <h3 className="h2 h2-lite">
          {entity && !responseStep1?.metadata?.entity && (
            <>
              Who are the owners of
              <span className="blue-bold">{entity.name}</span>
            </>
          )}
          {!entity && !responseStep1?.metadata?.entity &&
            'Who will be an owner of your new entity?'
          }

          {responseStep1?.metadata?.entity && (
            <>
              {step2Confirm && 'Confirm or edit the ownership of '}
              {!step2Confirm && !entity?.key.includes('-') && 'What is the new ownership structure of '}
              {!step2Confirm && entity?.key.includes('-') && 'Who are the owners of '}
              {entities && entities.length > 1 && (
                <DropdownBlue
                  label={responseStep1?.metadata?.entity}
                  items={entities
                    .map((item) => ({ key: item._id, label: item.name }))
                    .filter((item) => item.key !== responseStep1.key)}
                  onClickSelect={changeEntity}
                  className="dropdown-blue-bold"
                />
              )}
              {entities && entities.length <= 1 && (
                <span className="bold">{entity?.name}</span>
              )}
              ?
            </>
          )}
        </h3>
        {(
          (entity && !responseStep1?.metadata?.entity) ||
          (responseStep1?.metadata?.entity && !step2Confirm && entity?.key.includes('-')) ||
          (!entity && !responseStep1?.metadata?.entity)
        ) &&
          (
            <div className="h4 h4-w4" style={{ marginTop: 5 }}>
              Anyone with at least 20% ownership will automatically be added as a
              guarantor on the loan and we’ll need to run a credit and background
              check on them. <span className="bold cursor">Learn more</span>{' '}
              <ArrowRightOutlined className="cursor" />
            </div>
          )}

        {responseStep1?.metadata?.entity && !step2Confirm && !entity?.key.includes('-') && (
          <div className="h4 h4-w4" style={{ marginTop: 5 }}>
            Editing won’t affect closed loan applications, but if you have any
            active applications, the entity details will be updated there. Don’t
            want anything to change?{' '}
            <span className="bold cursor" onClick={previousStep}>
              Create a new entity instead
            </span>{' '}
            <ArrowRightOutlined className="cursor" onClick={previousStep} />
          </div>
        )}
        {responseStep1?.metadata?.entity && step2Confirm && (
          <div className="h4 h4-w4" style={{ marginTop: 5 }}>
            Editing won’t affect closed loan applications, but if you have any
            active applications, the entity details will be updated there. Don’t
            want anything to change?{' '}
            <span className="bold cursor" onClick={previousStep}>
              Create a new entity instead
            </span>{' '}
            <ArrowRightOutlined className="cursor" onClick={previousStep} />
          </div>
        )}
        <div className="content-cards mt-30">
          {ownerExpand && itemCardOwner(ownerExpand, 99)}

          {ownerships.filter((item: any) => {
            return !(item?._id && ownerExpand?._id === item?._id) ||
              (item?.ownerId && ownerExpand?.ownerId === item?.ownerId)
          }).map((ownership: any, index: number) =>
            itemCardOwner(ownership, index))
          }
          {newOwner && (
            <CardOwnershipEdit
              onFinish={createOwnership}
              ownership={newOwner}
              fetching={fetching}
              totalPercentage={getTotal()}
              disabledNames={ownerships.length === 0 && newOwner.name}
              onSaveDraft={(v) => createOwnership(v)}
              handlerClose={() => setNewOwner(null)}
              setNewPercentageLiveEdit={setNewPercentageLiveEdit}
            />
          )}
        </div>
        {!step2Confirm && (
          <div className="content-owner-new mt-10">
            <div className="text-add">
              <PlusCircleOutlined />
              <span>Add</span>
              <DropdownBlue
                label="someone new"
                items={getSomeoneNews(!!newOwner)}
                disabled={newOwner}
                className={`${newOwner && 'disabled'}`}
                onClickSelect={(key: string) => selectOwnersCache(key, setNewOwner, createOwnership)}
              />
              <span>as an owner</span>
            </div>
            <div className={`total ${getTotal() !== 100 && 'total-error'}`}>
              <span>Total</span>
              <b>{getTotal()}%</b>
            </div>
          </div>
        )}
        {ownerships?.length > 0 && (
          <div className="mt-10 submits">
            <ButtonSubmit
              entity={entity}
              ownerships={ownerships}
              step2Confirm={step2Confirm}
              newOwner={newOwner}
              responseStep1={responseStep1}
              taskEntityDocs={taskEntityDocs}
            />
            {step2Confirm && (
              <ButtonEditOwnership
                entity={entity}
                step2Confirm={step2Confirm}
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
};
export default Step2;
