import React, { useContext, useEffect, useState } from 'react';
import CardUploadResponse from '../../../../../../components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { useCreateResponseTask, useDeleteFileResponse } from '../../../../../../services/task/mutations';
import {
  File,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../../gql/graphql';
import { TaskContext } from '../TrackRecord';
import ModalFinishTask from './ModalFinishTask';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import AddAnotherDoc from 'scenes/Private/components/AddAnotherDoc/AddAnotherDoc';

interface Props {
  setStep: Function;
  closeTask: Function;
}
const Step2 = ({ setStep, closeTask }: Props) => {
  const task: Task | any = useContext(TaskContext);

  const { execute: _deleteFile } = useDeleteFileResponse();
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { fetching, execute: createResponseTask } = useCreateResponseTask();

  const [modalFinishTask, setModalFinishTask] = useState<boolean>(false);
  const [response, setResponse] = useState<TaskResponse | any>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    const _response = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  const submit = async (files: any) => {
    if (task) {
      await createResponseTask({
        _id: response?._id,
        key: 'purchased/sold',
        label: 'Details of properties you’ve purchased/sold',
        step: 1,
        metadata: {},
        state: TaskState.Review,
        task: task?._id,
        files
      });

      getTaskResponses({ requestPolicy: 'network-only' });
      setModalFinishTask(true);
    }
  };

  const onDeleteFile = (file: File, doc: TaskResponse) => {
    if(doc) _deleteFile({ fileKey: file.key, taskResponseId: doc._id });
  }

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          Give us some more info on the properties you’ve purchased/sold
        </h3>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask taskId={task._id} />
        }

        <div className="mt-60">
          <CardUploadResponse
            title="Details of properties you’ve purchased/sold"
            description="Fill out our template and upload it here. We’ll use this to get preliminary verification of your experience."
            isButtonTemplate={{ show: true, link: 'https://storage.googleapis.com/constlending-borrowers/static/BorrowerTrackRecordTemplate.xlsx' }}
            state={response?.state}
            files={response?.files}
            actionDeleteFile={(file: File) => onDeleteFile(file, response)}
            submit={{
              title: 'Submit for review',
              loading: fetching,
              disabled: response && task.state !== TaskState.RequestError,
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => submit(files)
            }}
          />
        </div>
      </div>

      {/*<AddAnotherDoc taskId={task._id} taskState={task.state}/>*/}

      <CollapseIssues taskId={task._id} rejectionCode='no_action_required'/>

      <ModalFinishTask
        visible={modalFinishTask}
        setVisible={setModalFinishTask}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step2;
