import React, { useContext } from 'react';
import CardMarketRent from './CardMarketRent/CardMarketRent';
import CardLeaseAgreements from './CardLeaseAgreements/CardLeaseAgreements';
import { TaskContext } from '../DscrQuestionsAgreements';

const Step3 = () => {
  // @ts-ignore
  const { totalUnits, setTotalUnits, vacantUnits, setVacantUnits, occupiedUnits, setOccupiedUnits } = useContext(TaskContext);
  
  return (
    <section className="Step3">
      <h2 className="h2">
        Give us market rents for the{' '}
        <span className="blue-bold">{vacantUnits} vacant units</span> and lease agreements
        for the <span className="blue-bold">{occupiedUnits} occupied units</span> in the
        property
      </h2>
      <p className="h4 h4-w4">
        We’ll review the leases and calculate your final DSCR for you
      </p>
      <div className="mt-25">
        <CardMarketRent vacantUnits={vacantUnits} />
      </div>
      <div className="mt-25">
        <CardLeaseAgreements ammountFiles={occupiedUnits} />
      </div>
    </section>
  );
};
export default Step3;
