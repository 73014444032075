import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import { useGetUserLoggin } from 'services/user/querys';
import { Task, TaskState } from 'gql/graphql';
import { useGetTasksByLoan } from 'services/task/querys';
import { Phases, PhasesLabel } from './LoanPhases.constanst';
import { useGetLoanByOwner } from 'services/loan/querys';
import useCardEntityGuarantor from './components/EntityGuarantorCreation/useCardEntityGuarantor';
import useCardMinimumLiquidity from './components/MinimumLiquidity/useCardMinimumLiquidity';
import useCardTrackRecord from './components/TrackRecord/useCardTrackRecord';
import useCardEntityDocs from './components/EntityDocs/useCardEntityDocs';
import useCardInvestment from './components/InvestmentExperienceVerification/useCardInvestment';
import useCardConstruction from './components/ConstructionBudget/useCardConstruction';
import useCardTitleCompany from './components/TitleCompany/useCardTitleCompany';
import useCardInsurance from './components/Insurance/useCardInsurance';
import useCardPurchase from './components/Purchase/useCardPurchase';
import useCardPayoff from './components/Payoff/useCardPayoff';
import useCardPropertyAccess from './components/PropertyAccessDetails/useCardPropertyAccess';
import useCardPayAppraisal from './components/PayAppraisal/useCardPayAppraisal';
import useCardDSCRQuestionsAgreements from './components/DscrQuestionsAgreements/useCardDscrQuestionsAgreements';
import { RulesTasksCards } from './RulesTaskCards.constanst';
import useCardNewTask from './components/NewTask/useCardNewTask';
import { TaskOpenModalContext } from '../Private';
import { UpdateDashboardContext } from './Dashboard';

const useDashboard = () => {
  let { loanId: loanIdParam }: any = useParams();
  const { setValue: setTaskOpenModal }: any = useContext(TaskOpenModalContext);

  const { data: user } = useGetUserLoggin();
  const { data: loans } = useGetLoanByOwner();

  const loanSelect = loans?.find(loan => loan._id === loanIdParam) || (loans && loans[0]);

  const [isCreateTaskResponse, setCreateTaskResponse] = useState<string>('idle');
  
  const [loanId, setLoanId] = useState<string>('');
  const [loan, setLoan] = useState<any | undefined>();
  const [filter, setFilter] = useState<string>('all');
  const [phaseSelect, setPhaseSelect] = useState<string>('approval');
  const [cacheTaskAll, setCacheTaskAll] = useState<any>();
  const [isUpdateDashboard, setUpdateDashboard] = useState<boolean>();

  const { data: tasks, reexecute: getTasksByLoan } = useGetTasksByLoan({
    variables: { loanId, state: filter },
    pause: true
  });

  const dscr_dummy = {
    // "_id": "66f348ee6cddaaec6f3b2aa8",
    "key": "dscr",
    "name": "DSCR",
    "description": null,
    "state": "DRAFT",
    "phase": null,
    "difficulty": null,
    "metadata": {},
    "responses": [
        // {
        //     "_id": "66f34a236cddaaec6f3b2ad2",
        //     "key": "me",
        //     "label": "Who will be coordinating access for appraisers and other vendors?",
        //     "step": 1,
        //     "state": "APPROVED",
        //     "metadata": null,
        //     "files": [],
        //     "__typename": "TaskResponse"
        // }
    ],
    "__typename": "Task"
  }

  const queryParams = new URLSearchParams(window.location.search);
  const dscrParam = queryParams.get('dscr');

  if (dscrParam === 'true') {
    // @ts-ignore
    tasks?.push(dscr_dummy);
  }

  const { card: cardPropertyAccess, dataTask: propertyAccess } = useCardPropertyAccess({ user, tasks });
  const { card: cardEntityGuarantor, dataTask: entityGuarantor } = useCardEntityGuarantor({ tasks });
  const { card: cardMinimumLiquidity, dataTask: minimumLiquidity } = useCardMinimumLiquidity({ tasks });
  const { card: cardTrackRecord, dataTask: trackRecord } = useCardTrackRecord({ tasks, loan: loanSelect });
  const { card: cardEnttiyDocs, dataTask: entityDocs } = useCardEntityDocs({ tasks, loan: loanSelect, entityGuarantor, changeViewModal: setTaskOpenModal });
  const { card: cardInvestment, dataTask: investment } = useCardInvestment({ tasks, loan: loanSelect, trackRecord });
  const { card: cardConstruction, dataTask: construction } = useCardConstruction({ tasks });
  const { card: cardTitleCompany, dataTask: titleCompany } = useCardTitleCompany({ tasks });
  const { card: cardInsurance, dataTask: insurance } = useCardInsurance({ tasks });
  const { card: cardPurchase, dataTask: purchase } = useCardPurchase({ tasks });
  const { card: cardPayoff, dataTask: payoff } = useCardPayoff({ tasks });
  const { card: cardPayAppraisal, dataTask: payAppraisal } = useCardPayAppraisal({ tasks });
  const { card: cardDSCR, dataTask: dscr } = useCardDSCRQuestionsAgreements({ tasks, loan: loanSelect });
  const { cards: cardsNewTasks } = useCardNewTask({ tasks });

  const _taskCards = [
    [cardPropertyAccess, propertyAccess],
    [cardEntityGuarantor, entityGuarantor],
    [cardMinimumLiquidity, minimumLiquidity],
    [cardTrackRecord, trackRecord],
    [cardEnttiyDocs, entityDocs],
    [cardInvestment, investment],
    [cardConstruction, construction],
    [cardTitleCompany, titleCompany],
    [cardInsurance, insurance],
    [cardPurchase, purchase],
    [cardPayoff, payoff],
    [cardPayAppraisal, payAppraisal],
    [cardDSCR, dscr]
  ];

  cardsNewTasks?.map((newTask: any) => {
    let isCard = RulesTasksCards.find(item => item.key === newTask.data._id);
    let _newTask = {
      key: newTask.data._id,
      modalKey: newTask.data._id,
      name: newTask.data.name,
      card: newTask,
      data: newTask.data,
      phase: [newTask.data?.phase],
      state: newTask.data?.state
    };

    if (!isCard)
      RulesTasksCards.push(_newTask as any);
    else {
      isCard.state = _newTask.state;
      isCard.data = _newTask.data;
      isCard.card = newTask;
    }
  })

  for (const rules of RulesTasksCards) {
    const findTaskCard = _taskCards.find(item => item && item[0]?.key === rules.key);
    if (findTaskCard) {
      const [card, data] = findTaskCard;
      if (card) rules.card = card;
      if (data) rules.data = data as Task;
      rules.state = rules.state !== TaskState.Approved ? data?.state : rules.state;
    }
  }
  
  useEffect(() => {
    if (loans && loans.length > 0 && loanSelect) {
      setLoanId(loanSelect._id);
      setLoan(loanSelect);
    }
  }, [loans, loanIdParam]);
  
  useEffect(() => {
    if (loanId || isUpdateDashboard) getTasksByLoan({ requestPolicy: 'network-only' });

    if(isUpdateDashboard) setUpdateDashboard(false);
  }, [loanId, filter, isUpdateDashboard]);

  useEffect(() => {
    setCacheTaskAll(undefined);
    setFilter('all');
  }, [phaseSelect]);

  const getTasksCards = () => {
    const taskCards: CardLoanTasksProps[] = [], 
      tasksFilter = [], 
      phaseSection: any[] = [
        { key: Phases.APPROVAL, label: 'Approval', tasks: [], state: 'DONE' },
        { key: Phases.DUE_DILIGENCE, label: 'Due Diligence', tasks: [], state: 'DONE' },
        { key: Phases.FINAL, label: 'Final Approval', tasks: [], state: 'DONE' },
      ];

    if (!RulesTasksCards)
      return [];
    
    for (const taskCard of RulesTasksCards) {
      
      if (
        taskCard.card &&
        (filter === 'all' || taskCard.state === filter) &&
        (!taskCard.filters || taskCard.filters?.includes(filter)) &&
        (!taskCard.loanType || taskCard.loanType?.includes(loan?.type)) &&
        (!taskCard.loanPurposes || taskCard.loanPurposes?.includes(loan?.purpose))
      ) {
        tasksFilter.push(taskCard.data);
        const _phase = phaseSection.find(item => item.key === taskCard.phase[0]);
        
        _phase.tasks.push(taskCard.card);
        if(taskCard.state !== TaskState.Approved)
          _phase.state = 'DO';
      }
    }

    for (const phase of phaseSection) {
      const isDoLater = phase.tasks.every((taskCard: any) => 
        taskCard.state === TaskState.Draft && 
        taskCard.stateRename?.DRAFT === 'COMING SOON'
      )
      
      if(isDoLater)
        phase.state = 'DO_LATER'
    }
    
    if (!cacheTaskAll && tasksFilter.length > 1)
      setCacheTaskAll(tasksFilter);

    //console.log(taskCard.card?.stateRename[taskCard.state as any]);
  
    return { taskCards, phaseSection, tasksFilter: cacheTaskAll || tasksFilter };
  }

  const { tasksFilter, taskCards, phaseSection }: any = getTasksCards();

  const getPhaseNow = () => {
    const approvalDone = RulesTasksCards.filter(taskCard =>
      taskCard.phase.includes(Phases.APPROVAL) &&
      (!taskCard.loanType || taskCard.loanType?.includes(loan?.type)) &&
      (!taskCard.loanPurposes || taskCard.loanPurposes?.includes(loan?.purpose))
    ).every(taskCard => taskCard.state === TaskState.Approved);

    if (approvalDone) return { label: PhasesLabel[Phases.DUE_DILIGENCE], key: Phases.DUE_DILIGENCE };

    const isApproval = RulesTasksCards.find(taskCard =>
      taskCard.phase.includes(Phases.APPROVAL) &&
      (!taskCard.loanType || taskCard.loanType?.includes(loan.type)) &&
      (!taskCard.loanPurposes || taskCard.loanPurposes?.includes(loan?.purpose)) &&
      taskCard.state !== TaskState.Draft
    );

    if (isApproval) return { label: PhasesLabel[Phases.APPROVAL], key: Phases.APPROVAL };

    const isPreApproval = RulesTasksCards.find(taskCard =>
      taskCard.phase.includes(Phases.PRE_APPROVAL) &&
      taskCard.state !== TaskState.Draft
    );

    if (isPreApproval) return { label: PhasesLabel[Phases.PRE_APPROVAL], key: Phases.PRE_APPROVAL };
  }

  const phaseNow = getPhaseNow();

  return {
    loans,
    loanSelect,
    tasks,

    isCreateTaskResponse,
    setCreateTaskResponse,

    isUpdateDashboard,
    setUpdateDashboard,

    phaseNow,
    phaseSelect,
    setPhaseSelect,

    filter,
    setFilter,

    rulesTasksCards: RulesTasksCards,
    tasksFilter,
    taskCards,
    phaseSection,
  }
};
export default useDashboard;