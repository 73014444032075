
export const IconAdditionalDocument = (props: any) => {

  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 554">
        <path id="Vector" d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#5E8EB7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M8 5.19727V10.7973" stroke="#5E8EB7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M5.19727 8H10.7973" stroke="#5E8EB7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>

  );
} 