import React, { Children, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Client, Provider, cacheExchange, fetchExchange, useQuery } from 'urql';
import { AuthConfig, authExchange } from '@urql/exchange-auth';

import Public from "./scenes/Public/Public";
import Private from './scenes/Private/Private';
import { useExchangeOAuthToken } from 'services/auth/mutations';
import { API_URL_GRAPHQL } from '@common/config/environments';

function App() {

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
  }

  const client = new Client({
    // url: API_URL_GRAPHQL || 'http://localhost:4000/graphql',
    url: 'https://sbb.constlending.com/graphql',
    exchanges: [
      cacheExchange,
      authExchange(async (utils: any) => {
        let token = await localStorage.getItem('token');

        const config: AuthConfig = {
          addAuthToOperation(operation) {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
              'Apollo-Require-Preflight': 'true'
            });
          },
          didAuthError(error, _operation) {
            return error.response?.status === 401
          },
          async refreshAuth() { logout() },
          willAuthError(_operation) {
            return false
          },
        }

        return config;
      }),
      fetchExchange
    ]
  });

  const [isLogged, setIsLogged] = useState<boolean>(false);

  useEffect(() => {
    setIsLogged(!!localStorage.getItem('token'));

    window.addEventListener("storage", () => {
      setIsLogged(!!localStorage.getItem('token'));
    });

    return () => {
      window.removeEventListener("storage", () => { });
    };
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ffffff',

          colorBgContainer: '#ffffff',
        },
      }}
    >
      <Provider value={client}>
        <Wrapper>
          {isLogged && <Private />}
          {!isLogged && <Public />}
        </Wrapper>
      </Provider>
    </ConfigProvider>
  )
}

const Wrapper = (props: any) => {
  const {
    execute: exchangeOAuthToken
  } = useExchangeOAuthToken()

  const oAuthToken = new URLSearchParams(window.location.search).get("oauth")

  useEffect(() => {
    if (oAuthToken) {
      exchangeOAuthToken({
        token: oAuthToken
      })
        .then(({ data }) => {
          if (data) {
            localStorage.removeItem('token');
            const token = data.exchangeOAuthToken;
            localStorage.setItem('token', token);
            localStorage.setItem('fromOAuth', 'true');

            window.dispatchEvent(new Event('storage'));

            const url = new URL(window.location.href);
            const loanId = url.searchParams.get("loanId");
            
            url.searchParams.delete("oauth");
            url.searchParams.delete("loanId");
            
            url.pathname = `/home/${loanId}`;
            window.history.replaceState({}, document.title, url.toString());
            window.location.reload();
          }
        })
    }
  }, [oAuthToken])

  return (props.children)
}

export default App;
