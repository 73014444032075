import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { formatterCurrency } from '@common/utils/formatters/formatter';
import { CurrencyInput } from '../../Step3/CardMarketRent/CardMarketRent';
import { TaskContext } from '../../DscrQuestionsAgreements';
import { useEffect, useRef } from 'react';
import { Loan } from 'gql/graphql';

interface Step1Props {
  setStep: Function;
  loan: Loan | any;
}

const CardEstimatedDSCR = ({ setStep, loan }: Step1Props) => {
  //@ts-ignore
  const { dscrValues, setDSCRValues } = useContext(TaskContext);
  const [isEditing, setIsEditing] = useState(false);

  const [currentDSCR, setCurrentDSCR] = useState(dscrValues.estimatedDSCR)

  const [touched, setTouched] = useState(false);
  const handleChange = ({ name, value }: any) => {
    setDSCRValues({
      ...dscrValues,
      [name]: value,
    });
    setTouched(true);
  };

  const PricerPayloadFactory = (loanObject: any, dscrValues: any) => {
    const pricerPayload = {
      fico: loanObject.loanData['What’s your credit score?'],
      loan_balance: loanObject.loanData.selectedOption.totalLoanAmount,
      price: loanObject.loanData.price,
      loan_purpose: loanObject.purpose.toLowerCase(),
      payoff: loanObject.loanData.currentLoanBalance,
      property_type: "Warrantable Condo",
      // property_type: loanObject.loanData['What kind of property is it?'],
      monthly_market_rent: dscrValues.monthlyRentalIncome || loanObject.loanData.monthlyRent,
      annual_property_taxes: dscrValues.annualPropertyTaxes || loanObject.loanData.annualTaxes,
      annual_insurance: dscrValues.annualPropertyInsurance || loanObject.loanData.annualInsurance,
      annual_hoa: dscrValues.annualHoaFee || loanObject.loanData.annualHoa,
      buydown: dscrValues.monthlyPayment || loanObject.loanData.selectedOption.buydownPoints
    };

    return pricerPayload;
  }

  const sendPricerPayload = async (pricerPayload: any) => {
    try {
      const response = await fetch('https://sbo.constlending.com/pricer/rental', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pricerPayload),
      });
      const data = await response.json();
      return data.data.dscr;
    } catch (error) {
      console.error('Error sending pricer payload', error);
      return null;
    }
  };

  useEffect(() => {
    if (touched) {
      const pricerPayload = PricerPayloadFactory(loan, dscrValues);
      sendPricerPayload(pricerPayload).then((dscr) => {
        setCurrentDSCR(dscr)
        console.log('Estimated DSCR:', dscr);
      });
    }
  }, [dscrValues]);

  const isValidDSCR = currentDSCR !== null

  return (
    <div className="CardEstimatedDSCR">
      <div className="card">
        <div className="card-row">
          <div className="card-column">
            <p className="h4 h4-w5">Income</p>
            <p className="h4 h4-w4">Monthly rental income:</p>
          </div>
          <div className="card-column">
            {
              isEditing ? (
                <CurrencyInput
                  value={dscrValues.monthlyRentalIncome}
                  onChange={(value: any) => handleChange({ name: 'monthlyRentalIncome', value })}
                  className={"dscr-input"}
                />
              ) : (
                <p className="h4 h4-w6">{formatterCurrency(dscrValues.monthlyRentalIncome)}</p>
              )
            }
          </div>
        </div>
        <div className="card-row">
          <div className="card-column">
            <p className="h4 h4-w5">Annual Expenses</p>
            <p className="h4 h4-w4">Annual property insurance:</p>
          </div>
          <div className="card-column">
            {
              isEditing ? (
                <CurrencyInput
                  value={dscrValues.annualPropertyInsurance}
                  onChange={(value: any) => handleChange({ name: 'annualPropertyInsurance', value })}
                  className={"dscr-input"}
                />
              ) : (
                <p className="h4 h4-w6">{formatterCurrency(dscrValues.annualPropertyInsurance)}</p>
              )
            }
          </div>
        </div>
        <div className="card-row">
          <div className="card-column">
            <p className="h4 h4-w4">Annual property taxes:</p>
          </div>
          <div className="card-column">
            {
              isEditing ? (
                <CurrencyInput
                  value={dscrValues.annualPropertyTaxes}
                  onChange={(value: any) => handleChange({ name: 'annualPropertyTaxes', value })}
                  className={"dscr-input"}
                />
              ) : (
                <p className="h4 h4-w6">{formatterCurrency(dscrValues.annualPropertyTaxes)}</p>
              )
            }
          </div>
        </div>
        <div className="card-row">
          <div className="card-column">
            <p className="h4 h4-w4">Annual HOA fee:</p>
          </div>
          <div className="card-column">
            {
              isEditing ? (
                <CurrencyInput
                  value={dscrValues.annualHOAFee}
                  onChange={(value: any) => handleChange({ name: 'annualHOAFee', value })}
                  className={"dscr-input"}
                />
              ) : (
                <p className="h4 h4-w6">{formatterCurrency(dscrValues.annualHOAFee)}</p>
              )
            }
          </div>
        </div>
        <div className="card-row card-row_footer">
          <div className="card-column">
            <p className="h4">Estimated DSCR</p>
          </div>
          <div className="card-column">
            {
              !isValidDSCR ?
                <p className="h4 h4-w6">Invalid DSCR</p>
                :
                <p className="h4 h4-w6">{currentDSCR || dscrValues.estimatedDSCR}</p>
            }
          </div>
        </div>
      </div>
      <div className="flex-btw">
        <Button style={{ color: "white"}} disabled={!isValidDSCR} className="button button-submit" onClick={() => setStep(2)}>
          Looks good
        </Button>
        <Button className="button button-secondary" disabled={!isValidDSCR} onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? "Save" : "I want to make edits"}
        </Button>
      </div>
    </div>
  );
};
export default CardEstimatedDSCR;
