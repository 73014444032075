import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import EntityDocs from './EntityDocs';
import { ENTITY_DOCS_LABEL, ENTITY_DOCS_RESPONSES } from './responses.constants';
import { Tooltip } from 'react-tooltip'
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { useGetEntitiesByOwner } from '../../../../../services/entity/querys';

interface Props {
  loan: Loan | any;
  tasks?: Task[] | any[],
  entityGuarantor: Task | undefined;
  changeViewModal: any;
}

const useCardEntityDocs = ({ loan, tasks, entityGuarantor, changeViewModal }: Props) => {

  const [ownerSelect, setOwnerSelect] = useState<string>('');

  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    {
      variables: { ownerId: ownerSelect },
      pause: true
    }
  );

  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [entity, setEntity] = useState<string>();
  const [notEntity, setNotEntity] = useState<boolean>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.ENTITY_DOCS);
      setDataTask(data);

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));

      const step1 = data?.responses?.find((item: any) => item.step === 1);

      if (step1 && !step1.metadata.entity) 
        setNotEntity(true);
    }
  }, [tasks]);
  
  useEffect(() => {
    if (entities && loan) {
      const _entity = entities.find((item) => item.loans.find((item2) => item2._id === loan._id));
      setEntity(_entity?.name);
    }
  }, [entities, loan]);

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner();
  }, [ownerSelect]);

  useEffect(() => {
    if (entityGuarantor) {
      const step1 = entityGuarantor.responses?.find((item: any) => item.step === 1);
      setOwnerSelect(step1?.metadata.ownerId);
    }
  }, [entityGuarantor]);
  
  const getResponse = (key: string) =>
    dataTask?.responses?.find((item) => item.key === key);

  const getTotalApproved = () =>
    dataTask?.responses?.filter((item: any) => item.state !== TaskState.Draft)?.length;

  const generateCirclesFooter: any = () => {
    const addAnotherDocs = dataTask?.responses?.filter(item => item.key === 'doc-another');

    const docs = addAnotherDocs?.map(item =>
      ({ state: item.state, name: item.label })
    ) || [];
    return docs;
  };

  const redirectEntityGuarantor = () => changeViewModal('entity_guarantor');

  const tooltipsTexts: any = {
    "APPROVED": 'Approved',
    "REVIEW": 'In Review',
    "REQUEST_ERROR": 'Rejected',
    "DRAFT": 'Not submmitted',
  }

  const tooltipTexts = (state: string) => tooltipsTexts[state];
  
  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.ENTITY_DOCS,
    title: <h4 className={`h4 ${!entity && 'disabled'}`}>Entity Documents and Details</h4>,
    steps: !notEntity? 1: 2,
    stateRename: {
      DRAFT: !entity? 'COMING SOON': undefined
    },
    stateTooltipHtml: `<span>${'Please complete '}</span><b>Entity Ownership And Guarantors</b><span> to unlock this</span>`,
    stepCurrent: stepNow?.step || 0,
    type: 'Major task',
    state: dataTask?.state,
    actions: dataTask ? [<EntityDocs key={1} task={dataTask} />] : [],
    text: entity ? (
      <p className="body-card">
        My entity is <span className="body-card-bold-line">{entity}</span>
      </p>
    ) : (
      <p className={`body-card ${!entity && 'disabled'}`}>
        Create or select an entity{' '}
        <span
          className="body-card-bold-line link link-underline"
          onClick={redirectEntityGuarantor}
        >
          here
        </span>{' '}
        to begin this task
      </p>
    ),
    text2: (
      <div className='docs-section'>
        <div className='labels'>
          <p className={`body-regular body-regular-bold ${!entity && 'disabled'}`}>
            Document status
          </p>
          <p className={`body-card ${!entity && 'disabled'}`}>
            {getTotalApproved()}/{(generateCirclesFooter()?.length || 0) + 4} submitted
          </p>
        </div>
        <div className={`circles-tags ${!entity && 'disabled'}`}>
          {Object.keys(ENTITY_DOCS_RESPONSES).map((key) => {
            let response = getResponse(ENTITY_DOCS_RESPONSES[key]);
            let _state = response?.state || 'DRAFT';

            return (
              <div key={key}>
                <div
                  data-tooltip-id={`circle-state-${key}`}
                  data-tooltip-html={`<b>${tooltipTexts(_state)}</b><br/><span>${ENTITY_DOCS_LABEL[key]}</span>`}
                  className={`circle circle-${_state}`}
                />
                <Tooltip 
                  id={`circle-state-${key}`}
                  className={`circle-state-tooltip circle-state-tooltip-${_state}`} 
                  />
              </div>
          )})}
          {generateCirclesFooter().map((item: any, index: number) => {
            let _state = item.state || 'DRAFT';

            return (
              <div key={index}>
                <div
                  data-tooltip-id={`circle-state-${_state}`}
                  data-tooltip-html={`<b>${tooltipTexts(_state)}</b><br/><span>${item.name}</span>`}
                  className={`circle circle-${_state}`}
                />
                <Tooltip 
                  id={`circle-state-${_state}`}
                  className={`circle-state-tooltip circle-state-tooltip-${_state}`} 
                />
              </div>
          )})}
        </div>
      </div>
    ),
  };

  if(!dataTask) 
    return { card: null };
  
  return {
    card,
    dataTask
  };
};

export default useCardEntityDocs;