import React, { useContext, useState } from 'react';
import CardAlertError from 'components/Atoms/Cards/CardAlertError/CardAlertError';
import QuestionBasic from 'components/Organisms/QuestionBasic/QuestionBasic';
import { Loan, Task, TaskState } from '../../../../../../gql/graphql';
import { TaskContext } from '../Insurance';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import ModalFinish from '../ModalFinish';
import { LoanContext } from '../../../Dashboard';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import { useGetUserLoggin } from 'services/user/querys';

interface Step1Props {
  setStep: Function;
  closeTask: Function;
}
const Step1 = ({ setStep, closeTask }: Step1Props) => {
  const task: Task | any = useContext(TaskContext);

  const { data: user } = useGetUserLoggin();
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { execute: createResponseTask } = useCreateResponseTask();

  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [optionSelect, setOptionSelect] = useState<{
    id: number;
    title: string;
  }>();

  const onChangeOption1 = async (option: {
    id: number;
    key: string;
    title: string;
  }) => {
    await createResponseTask({
      key: option.key,
      label: option.title,
      step: 1,
      metadata: { reviewRename: 'MANUAL ACTION' },
      state: option.key === 'no'? TaskState.Approved: TaskState.Review,
      task: task?._id
    });

    if(option.key === 'no')
      setStep(2);
    else {
      setModalFinish(true);
      getTaskResponses({ requestPolicy: 'network-only' });
    }
  };

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          You’ll need property and casualty insurance for us to close your loan
        </h3>
        <div className="mt-60">
          <QuestionBasic
            type="buttons"
            question={<h3 className="h2">Would you like an insurance quote from our partner, {user?.name} Insurance?</h3>}
            text={
              <p className="h4 h4-w4">
                If not, you’ll need to give us the contact information for your agent so we can reach out and make sure you’re insured
              </p>
            }
            optionSelected={optionSelect}
            options={[
              {
                id: 1,
                title: 'Yes, give me a quote',
                title2: `You’ll be redirected to our insurance partner, ${user?.name}, to get an estimate. We’ll also email you the link`,
                onClick: (option: any) =>
                  onChangeOption1({ key: 'yes', ...option })
              },
              {
                id: 2,
                title: 'No, I already have an insurance agent',
                onClick: (option: any) =>
                  onChangeOption1({ key: 'no', ...option }),
              }
            ]}
          />
        </div>
      </div>
      <ModalFinish
        visible={modalFinish}
        setVisible={setModalFinish}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step1;
