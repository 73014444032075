import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import Insurance from './Insurance';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { useGetUserLoggin } from 'services/user/querys';
import { Tooltip } from 'react-tooltip';

interface Props {
  tasks?: Task[] | any[],
}

const useCardInsurance = ({ tasks }: Props) => {

  const { data: user } = useGetUserLoggin();

  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [response, setResponse] = useState<TaskResponse>();
  const [responseDocs, setResponseDocs] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.INSURANCE);
      setDataTask(data);
      const step2: any = data?.responses?.find((item) => item.step === 1);
      setResponse(step2);
      const responseDocs = data?.responses.find(response => response.key === 'insurance-doc');
      setResponseDocs(responseDocs);

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const generateCirclesFooter: any = () => {
    if(!responseDocs)
      return [];
    
    const filesCirclesStates = new Array(responseDocs.files?.length).fill(undefined).map((item, index) => {
      if (responseDocs?.files && responseDocs.files[index])
        return responseDocs.files[index].state;

      return 'draft';
    });

    const addAnotherDocs = dataTask?.responses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || [];
    return [...filesCirclesStates, ...docsStates];
  };

  const getTotalApproved = () => {
    if (responseDocs)
      return responseDocs?.files?.filter((item: any) => item.state !== TaskState.Draft)?.length;
    else
      return 0;
  }

  const tooltipsTexts: any = {
    "APPROVED": 'Approved',
    "REVIEW": 'In Review',
    "REQUEST_ERROR": 'Rejected',
    "DRAFT": 'Not submmitted',
  }

  const tooltipTexts = (state: string) => tooltipsTexts[state];

  const isAwaitingAction = dataTask?.state === TaskState.Review;

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.INSURANCE,
    title: <h4 className="h4">Insurance</h4>,
    steps: response?.key === 'yes' ? 1 : 2,
    stepCurrent: stepNow?.step || 0,
    type: 'quick task',
    state: dataTask?.state,
    stateRename: isAwaitingAction ? { REVIEW: 'AWAITING ACTION' } : undefined,
    actions: dataTask ? [<Insurance key={1} task={dataTask} />] : [],
    text: (
      <div>
        {(dataTask?.state === TaskState.Draft || dataTask?.state === TaskState.Incomplete) && (
          <p className="body-card">
            I{' '}
            <span className="body-card-bold-line">
              {response?.key === 'yes' ? 'would,' :
                response?.key === 'no' ? 'would not,' : 'would/would not,'
              }
            </span>
            {' '}like an insurance quote
          </p>
        )}

        {dataTask && !(dataTask?.state === TaskState.Draft || dataTask?.state === TaskState.Incomplete) && (
          <>
            <p className="body-card">
              I{' '}
              <span className={`body-card-bold-line body-card-bold-line-${dataTask.state}`}>
                {response?.key === 'yes' ? 'would like' : 'would not like'}
              </span>
              {' '}an insurance qoute
            </p>
            {response && (isAwaitingAction || dataTask.state === TaskState.Approved) &&
              <div 
                className={`box-message-state-${dataTask.state === TaskState.Approved? 'approved': 'review'}`}
              >
                <span>
                  {
                    response?.key === 'yes' ?
                      `Check your email for an insurance quote and instructions` :
                      `We´ve reached out to your insurance and will update you`
                  }
                </span>
              </div>
            }
          </>
        )}
      </div>
    ),
    text2: !isAwaitingAction || !response || !responseDocs? null: (
      <div className='docs-section' style={{ marginTop: 5 }}>
        <div className='labels'>
          <p className={`body-regular body-regular-bold`}>
            Document status
          </p>
          <p className={`body-card`}>
            {getTotalApproved()}/{generateCirclesFooter()?.length || 0} submitted
          </p>
        </div>
        <div className={`circles-tags`}>
          {generateCirclesFooter().map((state: string, index: number) => {
            let _state = state.toUpperCase() || 'DRAFT';

            return (
              <div key={index}>
                <div
                  data-tooltip-id={`circle-state-${_state}`}
                  data-tooltip-html={`<b>${tooltipTexts(_state)}</b>`}
                  className={`circle circle-${_state}`}
                />
                <Tooltip
                  id={`circle-state-${_state}`}
                  className={`circle-state-tooltip circle-state-tooltip-${_state}`}
                />
              </div>
            )
          })}
        </div>
      </div>
    ),
    currentInfo: responseDocs? null: (
      <div>
        {isAwaitingAction && response?.key === 'yes' &&
          <>
            <p className={`body-card body-card-${dataTask?.state}`}>EMAIL</p>
            <div className="flex-btw">
              <p className={`body-card body-card-${dataTask?.state} body-card-w5`}>
                {user?.email}
              </p>
            </div>
          </>
        }
        {(isAwaitingAction || dataTask?.state === TaskState.Approved) && response?.key === 'no' && dataTask?.metadata?.name &&
          <>
            <p className={`body-card body-card-${dataTask?.state}`}>INSURANCE CONTACT</p>
            <div className="flex-btw">
              <p className={`body-card body-card-${dataTask?.state} body-card-w5`}>
                {dataTask?.metadata?.name}
              </p>
              <p className={`body-card body-card-${dataTask?.state}`}>{dataTask?.metadata?.email}</p>
              <p className={`body-card body-card-${dataTask?.state}`}>{dataTask?.metadata?.phone}</p>
            </div>
          </>
        }
      </div>
    )
  };

  if (!dataTask)
    return { card: null };

  return {
    card,
    dataTask
  };
};

export default useCardInsurance;