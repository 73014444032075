import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Step2 from './Step2/Step2';
import {
  Entity,
  Loan,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import { LoanContext } from '../../Dashboard';
import { useRemoveTaskResponse } from 'services/task/mutations';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
}

const Insurance = ({ task }: Props) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const loan: any = useContext(LoanContext);

  const { execute: removeTaskResponse } = useRemoveTaskResponse();
  const { data: loanDocs } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<TaskResponse>();
  const [responseStep2, setResponseStep2] = useState<TaskResponse>();
  const [entity, setEntity] = useState<Entity | any>();

  useEffect(() => {
    if (openContext === ModuleKeys.INSURANCE) {
      setModalTaskVisible(true);
      setHandler();
    } else if(!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    if (isModalTaskVisible)
      getTaskResponses({ requestPolicy: 'network-only' });
  }, [isModalTaskVisible])

  useEffect(() => {
    const step1: any = taskResponses?.find((item) => item.step === 1);
    setResponseStep1(step1);
    
    if (step1?.key === 'no') setStep(2);

    const step2: any = taskResponses?.find((item) => item.step === 2);
    setResponseStep2(step2);
    setIsReview(step2?.state === TaskState.Review);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const resetTask = (openModal: Function) => {
    //const docTitle = loanDocs?.find(item => item.key === 'insurance');
    //window.open(docTitle?.file?.url, "_blank");
    for (const response of task.responses) {
      removeTaskResponse({ taskResponseId: response._id });
    }

    setStep(1);
    getTaskResponses({ requestPolicy: 'network-only' });
    openModal(true)
    
  }

  const { taskName, phase } = findTaskRules(ModuleKeys.INSURANCE);
  
  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <ModalTask
          visible={isModalTaskVisible}
          handlerSetVisibile={setModalTaskVisible}
          isConfirmModalClose={task.state !== TaskState.Approved && !isReview}
          disabledDots={step === 2}
          responses={taskResponses}
          renderButtonTrigger={(openModal: any) => (
            <>
              {task?.state !== TaskState.Review && task?.state !== TaskState.Approved &&
                <Button
                className={`button-open-modal button-open-modal-${task.state}`}
                onClick={() => openModal(true)}
              >
                {(task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 
                  'Complete task' :
                  'Begin Task'
                }
              </Button>
              }
              {(task?.state === TaskState.Review || task?.state === TaskState.Approved) &&
                <Button
                  className={`button-open-modal button-open-modal-${task.state}`}
                  onClick={() => resetTask(openModal)}
                >
                  Change insurance type
                </Button>
              }
            </>
          )}
          header={{
            title: <span>{phase} | {taskName.toUpperCase()}</span>,
            actionLeft: (
              isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
            ),
            actionRight: (
              <Button
                className="button button-transparent"
                onClick={() => onHelpDocs()}
              >
                <QuestionCircleOutlined />
                Help docs
              </Button>
            )
          }}
          animationDirection="onRight"
          stepsTotal={2}
          currentStep={step}
          setStep={setStep}
          footer={
            <div className="text-footer mt-20">
              <p className="placeholder">
                My insurance is{' '}
                {step === 2 &&
                  <span className="bold-label">
                    through an agent
                  </span>
                }
              </p>
            </div>
          }
        >
          <section className="children AchPaymentForm">
            {step === 1 && (
              <Step1
                setStep={setStep}
                closeTask={() => setModalTaskVisible(false)}
              />
            )}
            {step === 2 && (
              <Step2
                setStep={setStep}
                closeTask={() => setModalTaskVisible(false)}
              />
            )}
          </section>
        </ModalTask>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default Insurance;
